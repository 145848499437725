.form-container {
  max-width: 300px;
  margin: auto;
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-field {
  margin-bottom: 1rem;
  width: 100%;
}

.form-input {
  width: 94.5%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #005f73;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-button:hover {
  background-color: #003f5c;
}

.LoginFail-container {
  max-width: 300px;
  margin: auto;
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.LoginFail-button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #d33f49;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.LoginFail-button:hover {
  background-color: #a12d40;
}

/* Add this to Styles.css */
.LoginSuccess-container {
  max-width: 300px;
  margin: auto;
  padding: 1rem;
  background-color: #d4edda;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #155724;
  border-color: #c3e6cb;
}

.LoginSuccess-button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.LoginSuccess-button:hover {
  background-color: #218838;
}
